<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2022-05-19 10:35:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div ref="pie" style="width:100%;height:100%;"></div>
</template>

<script>
// import util from "./util";
import { mapState } from "vuex";
export default {
  name: "pie",
  data() {
    return {
      pie:""
    };
  },
  props: ['data'],
  watch: {
    data: function() {
      this.initEcharts();
    },
    page_width: function() {
      this.initEcharts();
    },
    page_height: function() {
      this.initEcharts();
    },
  },
    computed: {
    ...mapState({
      //处理后的筛选数据list
      page_width: state => state.user.page_width,
      page_height: state => state.user.page_height,
    })
  },
  mounted() {
    var timeOut = setTimeout(() => {
        this.initEcharts();
        clearTimeout(timeOut);
    }, 2000);
  
    // window.addEventListener("resize", this.initEcharts);
  },
  methods: {
    // 初始化echarts图表
    initEcharts() {
      const this_ = this;
      if (this.pie) {
        this.pie.dispose();
      }
      this.pie = this.$echarts.init(this_.$refs.pie);
      let data = [];
      let option = this_.chartInt(data);
      var timeOut = setTimeout(() => {
        this_.pie.setOption(option);
        // this_.pie.resize();
        clearTimeout(timeOut);
      }, 1);
    },
    // 配置options
    chartInt(data) {
      
        //实际占比值 -> 半圆50%既为100% 则计算公式 = 所得占比值百分比值去掉%/2
        // const cirNum = monthCheckData.shouldCheckIn ?  ((monthCheckData.checkIn || 0)*100 / ((monthCheckData.shouldCheckIn || 0) * 2)) : 0;
        //小白点->由pie 透明圆环+label圆点组成
        const seriesOption = [ 
            {
                color: ['#fff', '#fff'],
                z: 1,
                type: 'pie',
                center: ['50%', '50%'],
                radius: ['66%', '72%'],
                hoverAnimation: true,
                avoidLabelOverlap: true,
                silent: true,
                clickable: true,
                startAngle: 90,
                data: [
                    {
                        name: '',
                        //0-> 100 100  25%-> 值圈125 0 剩余占比圈75    50%-> 值圈150 剩余占比圈50  75% ->值圈175 剩余占比圈25     ---> 值圈= 100+实际占比值  剩余占比圈 = 100-实际占比值
                        // cirNum/5 空出小白点头部虚位
                        value: 100,
                        hoverAnimation: true,
                    

                        // borderRadius: 13,
                        roundCap: true,
                        itemStyle: {
                            normal: {
                                // barBorderRadius: [10, 10, 10, 10],
                                // color: {
                                //     // 完成的圆环的颜色
                                //     colorStops: [
                                //         {
                                //             offset: 0,
                                //             color: 'rgba(0, 0, 0, 1)', // 0% 处的颜色
                                //         },
                                //         {
                                //             offset: 1,
                                //             color: 'rgba(0, 0, 0, 1)', // 100% 处的颜色
                                //         },
                                //     ],
                                // },
                                color: 'rgba(252, 252, 252, .46)',
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    show: false,
                                },
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                    },
                    
                    //画剩余的刻度圆环
                    // {
                    //     name: '',
                    //     // cirNum == 0 ? 98 : (100 - cirNum)
                    //     value: 70,
                    //     label: {
                    //         show: false,
                    //     },
                    //     labelLine: {
                    //         show: false,
                    //         emphasis: {
                    //             show: false,
                    //         },
                    //     },
                    //     itemStyle: {
                    //         color: '#f60',
                    //     },
                    // },
                ],
            },
            
        
        ];

        //显示占比的值
        // const cirval = monthCheckData.shouldCheckIn ?  ((monthCheckData.checkIn || 0)*100 / (monthCheckData.shouldCheckIn || 0)).toFixed(1) : '0'
        
        const option = {
            title: 0 == 1? {} : [
              {
                text: "{num|"+this.data+"%}",
                textStyle: {
                  rich: {
                    num: {
                      color: '#FFD96E',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      // fontFamily: 'PingFangSC-Semibold, PingFang SC',
                      // fontSize:24 * 1,
                      fontSize: this.$util.setFont(60),
                      fontFamily: "DIGIB",
                      verticalAlign: "top"
                    },
                  }
                },
                x: "center",
                y: '28%'
              },
              {
                top: '54%',
                left: 'center',
                text: '完成率',
                textStyle: {
                  color: '#fff',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontFamily: 'PingFangSC-Regular, PingFang SC',
                  fontSize: this.$util.setFont(18),
                },
              },
            ],
            // backgroundColor: 'transparent',
            // color: '#1890FF',
            tooltip: {
                show: false
            },
            angleAxis: {
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                min: 0,
                max: 100,
                boundaryGap: ['0', '100'],
                startAngle: 90
            },
            radiusAxis: {
                type: 'category',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                data: ['a'],
                z: 10
            },
            // polar: {
            //     radius: ['82%', '100%'],
            //     center: ['50%', '50%'],
            // },
            toolbox: {
                show: false
            },
            series: [...seriesOption]
        };
        // 圆环颜色
        // const colorArr = ['#18D1BC', '#f60'];

        const colorArr = [
            // { // 完成的圆环的颜色
            //     colorStops: [{
            //       offset: 0,
            //       color: '#FF9A2C' // 0% 处的颜色
            //     }, {
            //       offset: 1,
            //       color: '#FFF38B' // 100% 处的颜色
            //     }]
            //   },
              { // 完成的圆环的颜色
                colorStops: [{
                  offset: 0,
                  color: '#FEF28C' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#FFCF13' // 100% 处的颜色
                }]
              },
              { // 完成的圆环的颜色
                colorStops: [{
                  offset: 0,
                  color: '#681abd' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#f4b4ff' // 100% 处的颜色
                }]
              },
              // 'rgba(24,209,188,0.5)'
        ];
       
        // const numArr = [cirNum == 0 ? 1 : cirNum, 50];
        const numArr = [this.data];
        // this.data
        //表达值的内环
        const seriesOption3 = numArr.map((item, index) => {
            // num += item.value
            const bar = {
                type: 'bar',
                data: [item],
                coordinateSystem: 'polar',
                z: 9 - index,
                name: '',
                roundCap: true,
                color: this.data > 100 ? colorArr[1] : colorArr[0],
                // barGap: '-100%',//柱体叠加
            }
            return bar
        });
        // const seriesOption3 =  {
        //     // num += item.value
        //     const bar = {
        //         type: 'bar',
        //         data: [50],
        //         coordinateSystem: 'polar',
        //         z: 9,
        //         name: '',
        //         roundCap: true,
        //         color: colorArr[0],
        //         // barGap: '-100%',//柱体叠加
        //         //柱周样式
        //         // itemStyle: {
        //         //     normal: {
        //         //         borderWidth: 6,
        //         //         shadowBlur: 10,
        //         //         // color: 'transparent',
        //         //         // borderColor: color[i],
        //         //         shadowColor: color[index]
        //         //     }
        //         // }
        //     };
        //     return bar;
        // };
        const option2 = {...option, 
            series:[...seriesOption, ...seriesOption3],
            polar: {
                radius: ['60%', '78%'],
                center: ['50%', '50%'],
            }
    };
      return option2;
    }
  }
};
</script>

