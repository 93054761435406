import { render, staticRenderFns } from "./revelry.vue?vue&type=template&id=b43b4b00"
import script from "./revelry.js?vue&type=script&lang=js&external"
export * from "./revelry.js?vue&type=script&lang=js&external"
import style0 from "./revelry.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports