import autoView from "../components/RespondBox";//响应式盒子
import custBuySwiper from "../components/CustBuySwiper";//60商家滚动组件
import countToAct from "../components/CountToAct";//数字滚动(带背景)
import pie from "../components/Pie";//数字滚动(带背景)
import { getProductInfo, getCustBuyMsg, getTarget, getTop10, marketCenter, spart, hotproduct, hotcust, hotcenter, finishtask, findByPlatform } from "../api";
import getData from "../getData";
import countTo from "vue-count-to";
import { SmileOutlined, MehOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    autoView,
    custBuySwiper,
    countToAct,
    countTo,
    pie,
    SmileOutlined
  },
  data() {
    return {
      index:0,//展示第几个商品的相关信息 默认第一个
      dataList:[],//活动商品列表 -》 用于键盘上下键获取商品信息 及 判断是否最后一个（提示）
      infoImg:"",//带信息的活动商品图
      productCode:"",//商品code -》 用于请求60商家 top10 进度
      
      changeLoading:true,//切换商品的loading开关 默认开启 请求第一次关闭
      // stepArr:[],//步骤条数量价格数据
      custList:[],//60商家 滚动数据
      topTenList:[],//top10 列表

      //百分比达成目标
      targetNum: 0,//目标数量
      // finishNum: 0,//完成数量
      // finishNumOld:0,//上次调用的完成数量
      unFinishNum: 0,//未完成数据
      unFinishNumArr:[],//未完成数据格式化后的数组['1', ',', '2', '3', '4']
      stepArr:[],//步骤条数量价格数据
      
      
      loadingControlArr:[false, false, false, false],//4项 0-请求完热销产品时开 1-请求完商家排名开 2-营销中心 3-完成进度
      timer1:null,//4项定时器
      timer2:null,
      timer3:null,
      timer4:null,
      finishNum1: 0,//完成数量
      finishNumOld1:0,//上次调用的完成数量
      finishNum2:0,//上次调用的完成数量
      finishNumOld2:0,//上次调用的完成数量
      sltForm:{//下拉框
        marketCenter:'',
        productGroupCode:'',
      },

      type:1,//商家排名类型1是渠道商家，2专卖店商家就
      marketCenterList:[],//营销中心下拉数据数组
      spartList:[],//产品线下拉数据数组
      hotproductList:[],//热销产品数据数组
      hotcustList:[],//商家排名数据数组
      hotcenterList:[],//营销中心数据数组
      finishtaskList:[],//完成进度数据
      qudaoWCL:0,//完成率1
      zhuanmaiWCL:0,//完成率2
    };
  },
  filters: {
    // 数据每个三位加，
    num: function(value) {
      // if(!value) return '0.00'
      value = value.toFixed(0);
      var intPart = Math.trunc(value);// 获取整数部分
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
      var floatPart = ""; // 预定义小数部分
      var value2Array = value.split(".");
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString(); // 拿到小数部分
        if (floatPart.length === 1) { // 补0,实际上用不着
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    }

  },
  computed: {
    finishRate(){
      // 完成率
      if(this.targetNum && this.finishNum) {
        if(this.finishNum >= this.targetNum){
          return 100
        }else{
          return parseInt((this.finishNum / this.targetNum) * 100);
        }
      }
      return 0;
    },
    isLoaing(){
      //接口报错状态下 不加载loadidng
      if(this.loadingControlArr.length == 0){
        return false;
      }
      //切换后
      if(!this.changeLoading){
        return false;
      }
      // if(this.loadingControlArr.every(item => item === false) && !this.changeLoading) {// 暂不用
      if(this.loadingControlArr.every(item => item === false)) {
        return false;
      }else{
        return true;
      }
    },
  },
  created() {
    const params = {
      method: 'marketingActivitiesRevelry',
      platform: 'dp',
      loginType: 'CS',
    };
    findByPlatform(params)
  },
  watch: {
    finishNum1(newNum, oldNum){
      //当前活动轮询时
      if(!this.changeLoading){
        this.finishNumOld1 = oldNum;
      }
    },
     finishNum2(newNum, oldNum){
      //当前活动轮询时
      if(!this.changeLoading){
        this.finishNumOld2 = oldNum;
      }
    },
    loadingControlArr: {
      handler() {
        if(this.loadingControlArr.every(item => item === false)){
          //全部加载完毕
          this.changeLoading = false;
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {

    //获取下拉
    this.getSltData();
    // //获取任务完成进度
    // this.getFinishtask();
    //获取信息
    this.getInfo();

    //屏幕自适应
    window.onresize = () => {
      this.$store.dispatch("user/changePageWidth");
      this.$store.dispatch("user/changePageHegiht");
    };
  },
  methods: {
    //获取营销中心/产品线下拉数据
    getSltData() {
      marketCenter().then(res => {
        if(res.data && res.data.code == 0 && res.data.list && res.data.list.length > 0){
          const arr_ = [{
            name:'全国',
            code:''
          }]
          this.marketCenterList = [...arr_, ...res.data.list];
        }
      }).catch(() => {
      });
      spart().then(res => {
        if(res.data && res.data.code == 0 && res.data.list && res.data.list.length > 0){
          const arr_ = [{
            name:'全产品线',
            code:''
          }]
          this.spartList = [...arr_, ...res.data.list];
        }
      }).catch(() => {
      });
      
    },
    //下拉切换
    sltChange(){
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      clearInterval(this.timer4);
      this.changeLoading = true;//暂不用
      this.getInfo();
    },
    //商家类型切换
    typeChange(type){
      if(this.type == type){
        return;
      }else{
        this.type = type;
      }
      this.changeLoading = true;//暂不用
      clearInterval(this.timer2);
      this.timer2 = null;
      this.getHotcust();
    },
    // spart, hotproduct, hotcust, hotcenter, finishtask
    //  获取商品带信息的图片
    async getInfo() {
      //获取热销产品（明星产品）
      this.getHotproduct();
      //获取商家排名
      this.getHotcust();
      // //获取营销中心排名
      this.getHotcenter();
      //获取任务完成进度
      this.getFinishtask();
    },


    //获取热销产品（明星产品）
    getHotproduct() {
      const params = {
        productGroupCode: this.sltForm.productGroupCode,
        marketCenter: this.sltForm.marketCenter,
        pageSize:10
      };
      this.$set(this.loadingControlArr,0,true)
      hotproduct(params).then(res => {
        this.$set(this.loadingControlArr,0,false)
        if(res.data && res.data.code == 0){
          this.hotproductList = res.data.list;
          //3s定时请求数据
          this.timer1 = setInterval(() => {
            this.getHotproduct();
            clearInterval(this.timer1);
          }, 10000);
        }else{
          this.loadingControlArr = [];
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    },

    //获取商家排名
    getHotcust() {
      const params = {
        productGroupCode: this.sltForm.productGroupCode,
        marketCenter: this.sltForm.marketCenter,
        type: this.type,
        pageSize:10
      };
      this.$set(this.loadingControlArr,1,true)
      hotcust(params).then(res => {
        this.$set(this.loadingControlArr,1,false)
        if(res.data && res.data.code == 0){
          this.hotcustList = res.data.list;
          //3s定时请求数据
          this.timer2 = setInterval(() => {
            this.getHotcust();
            clearInterval(this.timer2);
          }, 10000);
        }else{
          this.loadingControlArr = [];
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    },
    
    //获取营销中心
    getHotcenter() {
      const params = {
        productGroupCode: this.sltForm.productGroupCode,
        marketCenter: this.sltForm.marketCenter,
        pageSize:999
      };
      this.$set(this.loadingControlArr,2,true)
      hotcenter(params).then(res => {
        this.$set(this.loadingControlArr,2,false)
        if(res.data && res.data.code == 0){
          this.hotcenterList = res.data.list;
          //3s定时请求数据
          this.timer3 = setInterval(() => {
            this.getHotcenter();
            clearInterval(this.timer3);
          }, 10000);
        }else{
          this.loadingControlArr = [];
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    },

    //获取任务完成进度
    getFinishtask() {
      const params = {
        productGroupCode: this.sltForm.productGroupCode,
        marketCenter: this.sltForm.marketCenter,
        // pageSize:999
      };
      this.$set(this.loadingControlArr,3,true)
      finishtask(params).then(res => {
        this.$set(this.loadingControlArr,3,false)
        if(res && res.data){
          this.finishtaskList = res.data;
          this.finishNum1 = res.data.qudao.finishPrice;//完成数量
          this.finishNum2 = res.data.zhuanmai.finishPrice;//完成数量
          this.qudaoWCL = res.data.qudao.targetMoney ? (res.data.qudao.finishPrice * 100/ res.data.qudao.targetMoney).toFixed(0) : 0;
          this.zhuanmaiWCL = res.data.zhuanmai.targetMoney ? (res.data.zhuanmai.finishPrice * 100 / res.data.zhuanmai.targetMoney ).toFixed(0) : 0;
          //3s定时请求数据
          this.timer4 = setInterval(() => {
            this.getFinishtask();
            clearInterval(this.timer4);
          }, 10000);
        }else{
          this.loadingControlArr = [];
        }
      }).catch(() => {
        this.loadingControlArr = [];
      });;
    },


  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    clearInterval(this.timer4);
    this.timer1 = null;
    this.timer2 = null;
    this.timer3 = null;
    this.timer3 = null;
  }
};